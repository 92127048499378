import React from "react";
import { useState } from "react";

const Contact = () => {
  const [data, setData] = useState({
    fullname:"",
    phone:"",
    email:"",
    msg:"",

  });
   const InputEvent = (event)=>{
    const {name, value} = event.target;
    setData((preVal)=>{
      return {
        ...preVal,
        [name] : value,
      };
    });

   };
  const formSubmit = (e)=>{
    e.preventDefault();
    alert(` My name is ${data.fullname}. My Moile number is ${data.phone} and email is ${data.email}, Here is what I want to say ${data.msg}`);


  }
  return (
    <>
      <div className="my-1 form_div">
        <h1 className="text-center">Contact US</h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form action="mailto:sonusahu3715@gmail.com" onSubmit={formSubmit} className=" row needs-validation">
              <div className="mb-1">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  FullName
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter your name"
                  name="fullname"
                  value={data.fullname}
                  onChange={InputEvent}
                />
              </div>
              <div className="mb-1">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Mobile Number"
                  name="phone"
                  value={data.phone}
                  onChange={InputEvent}
                />
              </div>
              <div className="mb-1">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  name="email"
                  value={data.emai}
                  onChange={InputEvent}
                />
              </div>
              <div className="mb-1">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="1" 
                  name="msg"
                  value={data.msg}
                  onChange={InputEvent}
                ></textarea>
              </div>
              <div className="col-12 mb-5 mt-2">
                <button className="btn btn-outline-primary mb-5 " type="submit">
                  Submit form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
