// import React from 'react'
import web from "../src/images/website.webp";
import app from "../src/images/appdev.webp";
import software from "../src/images/software.webp";
import marketing from "../src/images/market.webp";
import blog from "../src/images/blog.jfif";
import wordpress from "../src/images/wordpress.webp";

const Sdata = [
    {
        imgsrc: web,
        title: "web development",
    },
    {
        imgsrc: app,
        title: "App development",
    },
    {
        imgsrc: software,
        title: "Software development",

    },
    {
        imgsrc: marketing,
        title: "Marketing",
    },
    {
        imgsrc: blog,
        title: "Blog development",
    },
    {
        imgsrc: wordpress,
        title: "WordPress development",
    },
];
export default Sdata;

