import React from 'react'

const Footer = () => {
  return (
    <>
    <footer className='bg-light text-center '>
        <p className='fixed-bottom badge bg-primary text-wrap'>&copy; 2023 Sonu Sahu. All Rights Reserved | Terms and Conditions</p>

    </footer>
    </>
  )
}

export default Footer